<template>
    <div id="Course">
        <FloatingOctave></FloatingOctave>
        <div class="main">
            <div class="section section-special-title">
                <div class="wrap">
                    <div class="title" data-aos="fade-up" :data-aos-once="true">
                        <h1>程式設計入門</h1>
                        <a
                            class="register-button"
                            target="_blank"
                            :href="formURL"
                        >
                            報名連結
                        </a>
                    </div>
                </div>
            </div>
            <div
                class="section section-banner"
                id="section-banner"
                name="section-banner"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <h1
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-anchor="#section-banner"
                >
                    <span>暑期衝刺兩週課程</span>
                    <span>零基礎者上手程式</span>
                </h1>
                <div class="h2-wrap">
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="600"
                        data-aos-anchor="#section-banner"
                    >
                        帶你了解 C
                        語言的資料型別、語句與基礎演算法，自己動手開發小程式
                    </h2>
                    <h2
                        data-aos="fade-up"
                        data-aos-delay="800"
                        data-aos-anchor="#section-banner"
                    >
                        體驗 HTML/CSS 網頁架構與撰寫，實作出專屬於你的網頁
                    </h2>
                </div>
                <div
                    class="logos"
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-anchor="#section-banner"
                ></div>
            </div>
            <div class="section section-intro">
                <div class="wrap">
                    <h1 data-aos="fade-up">{{ introData.h1 }}</h1>
                    <ul>
                        <li
                            v-for="(card, index) in introData.card"
                            :key="'intro-card-' + index"
                            :id="'intro-card-' + index"
                            :name="'intro-card-' + index"
                        >
                            <i
                                :class="'iconfont icon-' + card.icon"
                                data-aos="fade-up"
                                :data-aos-delay="200 + index * 200"
                                :data-aos-anchor="'#intro-card-' + index"
                            ></i>
                            <div
                                class="intro-card-wrap"
                                data-aos="flip-right"
                                :data-aos-delay="400 + index * 200"
                                :data-aos-anchor="'#intro-card-' + index"
                            >
                                <h2>{{ card.h2 }}</h2>
                                <p>{{ card.p }}</p>
                            </div>
                        </li>
                    </ul>
                    <a
                        class="register-button"
                        :href="formURL"
                        target="_blank"
                        data-aos="zoom-in"
                    >
                        立即報名
                    </a>
                </div>
            </div>
            <div class="section section-description">
                <div class="wrap">
                    <h1 data-aos="fade-up">{{ descriptionData.h1 }}</h1>
                    <ul>
                        <li
                            v-for="(card, index) in descriptionData.card"
                            :key="'description-card-' + index"
                            data-aos="flip-right"
                            :data-aos-delay="400 + index * 100"
                        >
                            <span>0{{ index + 1 }}</span>
                            <div class="description-title">
                                <h2 class="title">{{ card.h2 }}</h2>
                                <h3 class="title">
                                    {{ card.h3 }}
                                </h3>
                            </div>
                            <p class="description-paragraph">
                                {{ card.p }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="section section-schedule">
                <div class="wrap">
                    <h1
                        class="schedule-title"
                        id="schedule-title"
                        name="schedule-title"
                        data-aos="fade-up"
                    >
                        {{ scheduleData.h1 }}
                    </h1>
                    <h3
                        class="schedule-subtitle"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-anchor="#schedule-title"
                    >
                        {{ scheduleData.h3 }}
                    </h3>
                    <div data-aos="fade-up" data-aos-delay="400">
                        <div class="schedule-top">
                            <div
                                class="label"
                                :class="{ active: activeSchedule === 'first' }"
                                @click="changeActiveSchedule('first')"
                            >
                                <i></i
                                ><span>{{ scheduleData.first.title }}</span>
                            </div>
                            <div
                                class="label"
                                :class="{ active: activeSchedule === 'second' }"
                                @click="changeActiveSchedule('second')"
                            >
                                <i></i
                                ><span>{{ scheduleData.second.title }}</span>
                            </div>
                            <ul class="left">
                                <li>
                                    日期｜{{
                                        scheduleData[activeSchedule].date[0]
                                    }}-{{
                                        scheduleData[activeSchedule].date[7]
                                    }}
                                </li>
                                <li>
                                    時間｜{{
                                        scheduleData[activeSchedule].time
                                    }}
                                </li>
                                <li>地點｜{{ scheduleData.location }}</li>
                                <li>
                                    費用｜{{ scheduleData.fee.main }}
                                    <span
                                        v-for="(item, index) in scheduleData.fee
                                            .sub"
                                        :key="'fee-memo-' + index"
                                        >{{ item }}</span
                                    >
                                </li>
                            </ul>
                            <ul class="right">
                                <div class="qrcode"></div>
                                <li
                                    v-for="(value, key) in scheduleData.contact"
                                    :key="'contact-' + key"
                                >
                                    <i :class="'iconfont icon-' + key"></i>
                                    <span>{{ value }}</span>
                                </li>
                            </ul>
                        </div>
                        <ul class="schedule-bottom">
                            <li
                                class="schedule-card"
                                v-for="(item, index) in scheduleData.course"
                                :key="'card-' + index"
                            >
                                <h3>
                                    {{
                                        scheduleData[activeSchedule].date[index]
                                    }}
                                </h3>
                                <h3>COURSE #{{ index + 1 }}</h3>
                                <ul>
                                    <li
                                        v-for="(subitem, subindex) in item"
                                        :key="'chapter-' + subindex"
                                    >
                                        {{ index + 1 }}-{{ subindex + 1 }}
                                        {{ subitem }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <a
                        class="register-button"
                        :href="formURL"
                        target="_blank"
                        data-aos="zoom-in"
                    >
                        立即報名
                    </a>
                </div>
            </div>
            <div class="section section-qa">
                <div class="wrap">
                    <h1 data-aos="fade-up">{{ qaData.h1 }}</h1>
                    <ul>
                        <li
                            v-for="(item, index) in qaData.card"
                            :key="'qa-card-' + index"
                        >
                            <h2
                                data-aos="fade-up"
                                :data-aos-delay="200 + index * 100"
                            >
                                Q. {{ item.h2 }}
                            </h2>
                            <p
                                data-aos="flip-right"
                                :data-aos-delay="400 + index * 100"
                            >
                                {{ item.p }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue-demi'
import { FloatingOctave } from '../../components/index.js'
export default {
    name: 'Course',
    components: { FloatingOctave },
    setup() {
        let formURL = ref('https://forms.gle/Qtc5R7nJc9KJwGgN7')
        let introData = {
            h1: '最紮實的入門課程',
            card: [
                {
                    h2: '10個小程式與2份網頁',
                    p: '我們相信實作是學習的最好的途徑，這堂課中除了清晰的理論解說外，我們更重視讓學生透過動手來體驗，並用實際操作以暸解程式設計的核心。',
                    icon: 'programming',
                },
                {
                    h2: '豐富學習歷程檔案',
                    p: '這堂課程將給予學生大量的實作作品練習，可以直接成為學生未來申請大學時的學習歷程檔案內容，同時也培養學生的輯思維能力與程式撰寫的興趣。',
                    icon: 'education',
                },
            ],
        }
        let descriptionData = {
            h1: '加入「程式設計入門」，你將......',
            card: [
                {
                    h2: '認識資料型別與運算子',
                    h3: 'DATA TYPE & OPERATOR',
                    p: '這堂課會在課程中的小程式開發練習中，帶你認識並熟悉 C 語言最常見的資料型別，包含整數 int、浮點數 float、雙精度浮點數 double、長整數 long / long long 以及陣列 array。此外，藉由使用各式算術和比較運算子，我們可以完成各種各樣不同功能的函式。',
                },
                {
                    h2: '使用條件語句和循環語句',
                    h3: 'CONDITIONALS & LOOPS',
                    p: '我們也將在課堂中帶同學使用程式語言中最重要的兩種語句——條件語句（if/else）和循環語句（for/while）。學習這兩種語句後，再加上一些邏輯思考設計的能力，同學將會與我們一起完成數字比大小遊戲、猜數字遊戲等小程式的開發。',
                },
                {
                    h2: '了解搜尋與排序演算法',
                    h3: 'SEARCH & SORT ALGORITHM',
                    p: '在擁有資料和語句的基礎後，我們將會進入到邏輯層面更高的地方——演算法。在處理資料搜尋與排序問題時，有各種各樣的邏輯來針對同樣的需求進行優化，我們將會在這堂課認識最經典的二分搜尋演算法（Binary Search）以及冒泡排序（Bubble Sort）。',
                },
                {
                    h2: '學習網頁開發程式語言',
                    h3: 'HTML & CSS',
                    p: '我們將會在課堂中學習 HTML，用以架構出基礎的網頁資訊；並學習使用 CSS，將既有的網頁文檔進行美化。我們希望讓學生了解，針對前端使用者介面而言功能面固然重要，但建設出符合使用者邏輯思維與使用習慣的介面也同等重要。',
                },
                {
                    h2: '建立網頁設計切版思維',
                    h3: 'DESIGN IMPLEMENTATION',
                    p: '在取得網頁設計稿後，要如何將一份設計稿切分開各個版面，並使用先前學習過的網頁程式語言架構出和設計稿相同的網頁，是一門複雜的學問。這堂課中會帶同學實際了解如何對一份設計稿進行切版，並用程式建構出網頁。',
                },
            ],
        }
        let scheduleData = {
            h1: '程式設計入門課表',
            h3: '＜每一梯次限額 15 人，欲報從速！＞',
            first: {
                title: '第一梯次',
                date: [
                    '7/18（一）',
                    '7/19（二）',
                    '7/20（三）',
                    '7/21（四）',
                    '7/25（一）',
                    '7/26（二）',
                    '7/27（三）',
                    '7/28（四）',
                ],
                time: '09:20 - 12:20',
            },
            second: {
                title: '第二梯次',
                date: [
                    '8/1（一）',
                    '8/2（二）',
                    '8/3（三）',
                    '8/4（四）',
                    '8/8（一）',
                    '8/9（二）',
                    '8/10（三）',
                    '8/11（四）',
                ],
                time: '13:30 - 16:30',
            },
            location: '大自然科學文理補習班 板橋校',
            fee: {
                main: '6,600元',
                sub: [
                    '5/31 日前報名享有早鳥優惠價 6,000元',
                    '三人以上團報另有優惠請來電/line洽詢',
                ],
            },
            contact: {
                line: 'mnature02',
                phone: '02 8953 0228',
                address: '新北市板橋區中山路二段 62 號 3F',
            },
            course: [
                ['環境建置', '基本語法', '算術運算子', '資料型別（I）'],
                ['資料型別（II）', '比較運算子', '變數數值交換', '條件語句'],
                ['循環語句', '資料型別（III）', '搜尋演算法'],
                ['排序演算法', 'C語言總測驗'],
                ['編輯器', 'HTML基礎', 'CSS基礎'],
                ['CSS導入樣式', 'HTML頁面串接', 'CSS進階樣式'],
                ['切版邏輯', '彈性佈局', '三大定位'],
                ['CSS細節調整', '成果發表'],
            ],
        }
        let activeSchedule = ref('first')
        function changeActiveSchedule(str) {
            activeSchedule.value = str
        }
        let qaData = {
            h1: '程式設計入門課程 Q&A',
            card: [
                {
                    h2: '誰適合「程式設計入門」這門課？',
                    p: '這門課專為 14-18 歲的學生設計，對於沒有程式設計經驗或僅有一點基礎認識的同學，這堂課會帶你建立最紮實的程式語言邏輯基礎並陪養程式撰寫的興趣。',
                },
                {
                    h2: '「程式設計入門」課程時長為何？',
                    p: '程式入門設計暑期課程時長為兩週，每一梯次共八堂課，每堂課三小時。第一梯次為 7/18 - 7/28 的週一至週四每日 09:20-12:20，第二梯次為 8/1 - 8/11 的週一至週四每日 13:30-16:30。',
                },
                {
                    h2: ' 「程式設計入門」會有哪些實作作品？',
                    p: '程式入門設計暑期課程包含 10 個小程式與 2 份網頁作品。小程式包含 Hello world、加法器、整數溢出、暫存變數、數字比大小、猜數字遊戲 I、連續加法器、迴圈圖案印製、猜數字遊戲 II、亂碼排序；網頁作品包含 FAANG 介紹網頁以及客製化個人網頁。',
                },
                {
                    h2: '需要準備什麼樣的電腦或安裝什麼軟體？',
                    p: '參加這門課程的同學必須準備一台可以使用 wifi 連網的電腦，電腦的作業系統為 Windows 或 MacOS 皆可！課程中會帶領同學安裝開發環境與編譯器，同學們不需要事前進行任何準備。',
                },
            ],
        }

        return {
            formURL,
            introData,
            descriptionData,
            scheduleData,
            activeSchedule,
            changeActiveSchedule,
            qaData,
        }
    },
}
</script>

<style lang="scss" scoped>
$orange-border: 3px solid $major-dark;
$black-border: 3px solid $gray-4;
.main {
    position: relative;

    .section {
        position: relative;
        .wrap {
            position: relative;
            margin: 0 auto;
        }
    }
    a.register-button {
        display: block;
        margin: 50px auto;
        width: fit-content;

        text-decoration: none;
        @include largeButtonSetting($major-dark);
    }
}

.section-special-title {
    h1 {
        @include h1Setting($major-dark, true);
    }
    a.register-button {
        display: inline-block;
        margin: 0 10px 0 0;
        @include buttonSetting($major-dark);
    }
}

.section-banner {
    position: relative;
    color: white;
    text-align: center;

    background: center / cover no-repeat;
    background-image: url('~@/assets/Course/banner-background.jpg');
    box-sizing: border-box;

    h1 {
        font-size: 3.6vw;
        font-weight: 200;
        letter-spacing: 0.2em;
        text-shadow: $shadow-text;
    }
    h2 {
        font-size: 2.4vw;
        font-weight: 600;
        line-height: 1.6em;
        letter-spacing: 0.1em;
        text-shadow: $shadow-box;
    }
    .logos {
        position: absolute;
        bottom: 20px;
        background: center / contain no-repeat;
        background-image: url('~@/assets/Course/logos.png');
    }
}
.section-intro {
    h1 {
        @include h1Setting($gray-4);
    }
    ul {
        li {
            position: relative;
            i.iconfont {
                position: absolute;
                top: 15px;
                right: -40px;
                color: $major-dark;
                font-size: 120px;
            }
            .intro-card-wrap {
                position: relative;
                z-index: 50;
                padding: 40px;

                background-color: white;
                border-top: $orange-border;
                box-shadow: 3px -5px 15px rgba(black, 0.2);

                h2 {
                    @include h2Setting($major-dark);
                    text-align: left;
                    margin-bottom: 40px;
                }
                p {
                    @include pSetting();
                }
            }
        }
    }
}
.section-description {
    padding: 80px 0;
    background-color: $gray-2;
    h1 {
        @include h1Setting($major-light);
    }
    li {
        position: relative;
        z-index: 50;

        padding: 15px 30px 5px;

        box-sizing: border-box;
        box-shadow: -15px 3px 15px rgba($color: #000000, $alpha: 0.2);
        border-left: $orange-border;

        span {
            display: block;
            margin-bottom: 1.5em;

            color: $major-light;
            font-weight: 300;
            letter-spacing: 0.05em;
        }
        h2 {
            @include h2Setting(white);
            text-align: left;
        }
        h3 {
            @include h3Setting(white);
            text-align: left;
        }
        p {
            @include pSetting(white);
            margin-top: 2em;
        }
    }
}
.section-schedule {
    h1.schedule-title {
        @include h1Setting($gray-4);
        margin-top: 1em;
        line-height: 1.5em;
    }
    h3.schedule-subtitle {
        @include h3Setting($major-dark);
        margin-bottom: 5em;
    }
    .schedule-top,
    .schedule-bottom {
        position: relative;
        padding: 40px;
        background-color: $translucent;
        border: $black-border;
    }
    .schedule-top {
        border-bottom: none;
        div.label {
            position: absolute;
            top: -43px;
            height: 40px;
            width: 155px;
            text-align: center;
            cursor: pointer;

            span {
                position: relative;
                z-index: 500;
                padding-left: 0.3em;
                color: $gray-4;
                text-align: left;
                font-weight: 500;
                line-height: 48px;
                transition: 0.3s;
            }
            &:first-child {
                left: -1px;
            }
            &:nth-child(2) {
                left: 158px;
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
                &::before {
                    content: '';
                    position: absolute;
                    left: -2px;
                    bottom: -3px;
                    width: 140px;
                    border: 40px solid transparent;
                    border-left-width: 10px;
                    border-right-width: 10px;
                    border-bottom-color: $gray-2;
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 2.6px;
                    bottom: 0px;
                    width: 134px;
                    border: 34px solid transparent;
                    border-left-width: 8.6px;
                    border-right-width: 8.6px;
                    border-bottom-color: $translucent;
                    transition: 0.3s;
                }
            }
            &.active {
                span {
                    color: white;
                }
                i::after {
                    display: none;
                }
            }
        }
        ul.left {
            li {
                @include h2Setting($gray-4);
                text-align: left;
                margin-bottom: 0.5em;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    display: block;
                    @include h3Setting($gray-4);
                    padding-left: 80px;
                    text-align: left;
                }
            }
        }
        ul.right {
            position: relative;
            .qrcode {
                position: absolute;
                right: 0;
                width: 90px;
                height: 90px;
                background: center / contain no-repeat;
                background-image: url('~@/assets/Course/line-qrcode.png');
            }
            li {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.1em;

                i.iconfont {
                    padding-right: 10px;
                }
            }
        }
    }
    .schedule-bottom {
        li.schedule-card {
            padding: 20px;
            box-shadow: $shadow-box;
            h3 {
                @include h3Setting($major-dark);
                text-align: left;
            }
            ul {
                margin-top: 30px;
                li {
                    color: $gray-4;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 2em;
                    letter-spacing: 0.05em;
                }
            }
        }
    }
}

.section-qa {
    margin-bottom: 0 !important;
    padding: 60px 0 80px;

    color: white;
    text-align: center;

    background: center / cover no-repeat;
    background-image: url('~@/assets/Course/qa-background.jpg');
    box-sizing: border-box;

    h1 {
        @include h1Setting(white);
        margin-bottom: 1em;
    }
    ul {
        li {
            h2 {
                @include h2Setting(white);
                margin-bottom: 0.5em;
                padding-left: 1.6em;
                text-align: left;
                text-indent: -1.6em;
            }
            p {
                margin: 0 35px;
                padding: 30px 25px;
                background-color: $translucent;

                @include pSetting();
                box-shadow: $shadow-box;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
    .wrap {
        width: 80%;
        max-width: 1200px;
    }
    .section-special-title {
        .title {
            h1 {
                text-align: left;
            }
            a.register-button {
                display: inline-block;
                margin: 0 10px 0 0;
            }
        }
    }
    .section-banner {
        margin: -20px 0 0;
        padding: 150px 0;
        h1 {
            span {
                margin: 0 0.5em;
            }
        }
        .h2-wrap {
            margin-top: 70px;
        }
        .logos {
            margin-top: 70px;
            width: 100%;
            height: 80px;
        }
    }
    .section-intro {
        ul {
            display: grid;
            grid-template: 1fr / repeat(2, 1fr);
            grid-gap: 80px;
            padding: 0 30px;

            .intro-card-wrap {
                margin-top: 90px;
            }
        }
    }
    .section-description {
        ul {
            display: grid;
            grid-template: auto / repeat(2, 1fr);
            grid-gap: 80px;
            padding: 80px 0;
        }
    }
    .section-schedule {
        .schedule-top {
            display: flex;
            justify-content: space-between;
            ul.right {
                line-height: 3em;
            }
        }
        .schedule-bottom {
            display: grid;
            grid-template: auto / repeat(4, 1fr);
            gap: 30px;
        }
    }
    .section-qa {
        padding: 60px 0 80px;
        ul {
            display: grid;
            grid-template: auto / repeat(2, 1fr);
            grid-gap: 50px;
        }
    }
}

@media screen and (max-width: 959px) and (min-width: 640px) {
    .wrap {
        width: 90%;
    }
    .section-special-title {
        .title {
            a.register-button {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .section-banner {
        margin: -20px 0 0;
        padding: 150px 50px;
        h1 {
            text-align: left;
            span {
                margin-right: 0.5em;
            }
        }
        .h2-wrap {
            margin-top: 70px;
            h2 {
                text-align: left;
            }
        }

        .logos {
            width: 60%;
            height: 80px;
        }
    }
    .section-intro {
        li {
            margin: 30px auto 0;
            padding-top: 90px;
            width: 480px;
        }
    }
    .section-description {
        li {
            margin: 80px auto 0;
            width: 480px;
        }
    }
    .section-schedule {
        .schedule-top {
            ul.right {
                margin-top: 30px;
                line-height: 2em;
            }
        }
        .schedule-bottom {
            display: grid;
            grid-template: auto / repeat(2, 1fr);
            gap: 30px;
        }
    }
    .section-qa {
        li {
            margin: 50px auto 0;
            width: 480px;
        }
    }
}

@media screen and (max-width: 639px) {
    .wrap {
        width: 90%;
    }
    .section-special-title {
        .title {
            a.register-button {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .section-banner {
        margin: -20px 0 0;
        padding: 90px 30px 150px;
        h1 {
            text-align: left;
            span {
                display: block;
                font-size: 32px;
                line-height: 1.6em;
            }
        }
        .h2-wrap {
            margin-top: 2em;
            h2 {
                margin-bottom: 0.5em;
                text-align: left;
                font-size: 16px;
            }
        }
        .logos {
            width: 80%;
            height: 80px;
        }
    }
    .section-intro {
        li {
            margin: 30px 0 0;
            padding-top: 90px;
        }
    }
    .section-description {
        h1 {
            line-height: 1.6em;
        }
        li {
            margin: 80px 0 0 10px;
        }
    }
    .section-schedule {
        .schedule-top,
        .schedule-bottom {
            padding: 30px;
        }
        .schedule-top {
            ul.left {
                li {
                    text-indent: -3.3em;
                    padding-left: 3.3em;
                    font-size: 20px;
                    span {
                        text-indent: 0;
                        padding: 0;
                        font-size: 14px;
                    }
                }
            }
            ul.right {
                .qrcode {
                    position: relative;
                    margin: 30px auto;
                    width: 160px;
                    height: 160px;
                }
                li {
                    font-size: 14px;
                    line-height: 2em;
                }
            }
        }
        .schedule-bottom > li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .section-qa {
        h1 {
            line-height: 1.6em;
        }
        li {
            margin: 50px auto 0;
        }
    }
}
</style>
